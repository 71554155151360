/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getEventoCaixaSCH = (campo, valor, condicao)=>{
   let filtro = new Filtro();
   filtro.perPage = 1;
   filtro.filters.addFilter(campo,valor,condicao);
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
   filtro.fields = ['*'];
   return filtro
};
export const EventoCaixaSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['descricao','tipo','conta_id']
   filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
   return filtro
}
