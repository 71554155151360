import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes: [
      {path: '/', name: 'Login',meta:{title: 'Login'}	, component: () => import(/* webpackChunkName: "Login" */ '../views/Login/Login.vue')},
      {
         path: '/dash',
         name: 'Dash',
         component: () => import(/* webpackChunkName: "Dash" */ '../views/Dash.vue'),
         children: [

            {
               path: '/principal', name: 'Principal', meta:{title: 'Principal'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Principal.vue')
            },
            // CADASTRO
            {path: '/fornecedores',name: 'Fornecedor',meta:{title: 'Fornecedor'},component: () => import(/* webpackChunkName: "Fornecedor" */ '../views/Cadastros/fornecedor/Fornecedor.vue')},
            {path: '/fornecedores/cadastro',name: 'FornecedorCadastro',meta:{title: 'Fornecedor'},component: () => import(/* webpackChunkName: "FornecedorCadastro" */ '../views/Cadastros/fornecedor/FornecedorCadastro.vue')},
            {path: '/fornecedores/:id',name: 'FornecedorCadastroId',meta:{title: 'Fornecedor'},component: () => import(/* webpackChunkName: "FornecedorCadastro" */ '../views/Cadastros/fornecedor/FornecedorCadastro.vue')},

            {path: '/evento_caixa',name: 'EventoCaixa',meta:{title: 'Evento Caixa'},component: () => import(/* webpackChunkName: "EventoCaixa" */ '../views/Cadastros/evento_caixa/EventoCaixa.vue')},
            {path: '/evento_caixa/cadastro',name: 'EventoCaixaCadastro',meta:{title: 'Evento Caixa'},component: () => import(/* webpackChunkName: "EventoCaixaCadastro" */ '../views/Cadastros/evento_caixa/EventoCaixaCadastro.vue')},
            {path: '/evento_caixa/:id',name: 'EventoCaixaCadastroId',meta:{title: 'Evento Caixa'},component: () => import(/* webpackChunkName: "EventoCaixaCadastro" */ '../views/Cadastros/evento_caixa/EventoCaixaCadastro.vue')},

            {path: '/usuarios',name: 'Usuário',meta:{title: 'Usuários'},component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/usuarios/Usuario.vue')},
            {path: '/centro_custo',name: 'CentroCusto',meta:{title: 'Centro Custo'},component: () => import(/* webpackChunkName: "CentroCusto" */ '../views/Cadastros/centro_custo/CentroCusto.vue')},
            {path: '/grupo_acesso',name: 'GrupoAcesso',meta:{title: 'Grupo de Acesso'},component: () => import(/* webpackChunkName: "GrupoAcesso" */ '../views/Cadastros/grupo_acesso/GrupoAcesso.vue')},
            {path: '/contas',name: 'Conta',meta:{title: 'Conta'},component: () => import(/* webpackChunkName: "Conta" */ '../views/Cadastros/conta/Conta.vue')},

            // MOVIMENTACAO
            {path: '/conta_pagar',name: 'ContaPagar',meta:{title: 'ContaPagar'},component: () => import(/* webpackChunkName: "ContaPagar" */ '../views/Movimentacao/conta_pagar/ContaPagar.vue')},
            {path: '/conta_pagar/cadastro',name: 'ContaPagarCadastro',meta:{title: 'Conta Pagar'},component: () => import(/* webpackChunkName: "ContaPagarCadastro" */ '../views/Movimentacao/conta_pagar/ContaPagarCadastro.vue')},
            {path: '/conta_pagar/:id',name: 'ContaPagarCadastroId',meta:{title: 'Conta Pagar'},component: () => import(/* webpackChunkName: "ContaPagarCadastro" */ '../views/Movimentacao/conta_pagar/ContaPagarCadastro.vue')},

            {path: '/caixa',name: 'Caixa',meta:{title: 'Caixa'},component: () => import(/* webpackChunkName: "Caixa" */ '../views/Movimentacao/caixa/Caixa.vue')},
            {path: '/caixa/cadastro',name: 'CaixaCadastro',meta:{title: 'Caixa'},component: () => import(/* webpackChunkName: "CaixaCadastro" */ '../views/Movimentacao/caixa/CaixaCadastro.vue')},
            {path: '/caixa/:id',name: 'CaixaCadastroId',meta:{title: 'Caixa'},component: () => import(/* webpackChunkName: "CaixaCadastro" */ '../views/Movimentacao/caixa/CaixaCadastro.vue')},

            {path: '/previsao_caixa',name: 'PrevisaoCaixa',meta:{title: 'Previsão de Caixa'},component: () => import(/* webpackChunkName: "PrevisaoCaixa" */ '../views/Movimentacao/previsao_caixa/PrevisaoCaixa.vue')},
            {path: '/previsao_caixa/cadastro',name: 'PrevisaoCaixaCadastro',meta:{title: 'Previsao Caixa'},component: () => import(/* webpackChunkName: "PrevisaoCaixaCadastro" */ '../views/Movimentacao/previsao_caixa/PrevisaoCaixaCadastro.vue')},
            {path: '/previsao_caixa/:id',name: 'PrevisaoCaixaCadastroId',meta:{title: 'Previsao Caixa'},component: () => import(/* webpackChunkName: "PrevisaoCaixaCadastro" */ '../views/Movimentacao/previsao_caixa/PrevisaoCaixaCadastro.vue')},

            {path: '/empresa',name: 'Empresa',meta:{title: 'Empresa'},component: () => import(/* webpackChunkName: "Empresa" */ '../views/Configuracao/empresa/Empresa.vue')},

            {path: '/auditoria',name: 'Auditoria',meta:{title: 'Auditoria'},component: () => import(/* webpackChunkName: "Auditoria" */ '../views/Auditoria/Auditoria.vue')},
            {path: '/fluxo_caixa',name: 'Fluxo de Caixa',meta:{title: 'Fluxo de Caixa'},component: () => import(/* webpackChunkName: "Fluxo de Caixa" */ '../views/Relatorio/FluxoCaixa.vue')},

         ]
      },
      {
         path: '/:pathMatch(.*)*',
         name: 'not-found',
         component: () => import(/* webpackChunkName: "404" */ '../views/NotFound/NotFound.vue')
      },

   ]
});
router.beforeEach((to, from) => {
   document.title = (to.meta?.title + ' - ' + 'Web Caixa')  ?? 'Web Caixa'
})

export default router
