/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {Fornecedor} from '../../models/fornecedor'
import {BaseDescSCH, getBaseSCH} from "../../search/BaseSCH";
import {AllFornecedorSCH, FornecedorSCH} from "../../search/FornecedorSCH";
import {CentroCustoSCH} from "../../search/CentroCustoSCH";

const url = '/fornecedor'

const state = {
   all: [],
   allList: [],
   fornecedor: new Fornecedor(),
   filtro: new Filtro()
}

const getters = {
   listaAllFornecedores: (state) => {
      return state.allList
   },

   listaFornecedores: (state) => {
      return state.all
   },
   pegaFornecedor: (state) => {
      return state.fornecedor
   },
   pegaFiltro: (state) => {
      return state.filtro
   }
}

const actions = {
   searchItem({commit}, search) {
      state.filtro.filters.search = [];
      state.filtro.filters.addSearch(['razao', 'fantasia', 'cidade', 'cnpj','uf','fone1'], '%' + search + '%', 'ilike')
   },
   async getItem({commit}, {id, onSucesso, onErro}) {
      try {
         var filtro = getBaseSCH('fornecedor_id', id, '=')
         const list = (await pegaDados(url + '/get', filtro)).data
         commit('SET_FORNECEDOR', list.data[0])
         onSucesso()
      } catch (error) {
         onErro()
         console.log(error)
      }
   },

   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', FornecedorSCH(perPage))
   },

   async setAllListFornecedores({commit}, {onSucesso, onErro}) {
      try {
         var filtro = AllFornecedorSCH('*')
         const list = (await pegaDados(url+'/get', filtro)).data
         commit('SET_ALL_LIST', list.data)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
      }
   },

   async setListFornecedoresComVenda({commit}, {onSucesso, onErro}) {
      try {
         const list = (await lista(url + '/all_com_venda')).data
         commit('SET_ALL_LIST', list)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
      }
   },
   async setAllFornecedores({commit}, {onSucesso, onErro}) {
      try {
         const list = (await pegaDados(url + '/get', state.filtro)).data
         commit('SET_FORNECEDORES', list)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
         nao_autorizado(error)
      }
   },
   async processarFornecedor({commit}, {acao, onSucesso, onErro}) {
      try {
         let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.fornecedor, acao)

         this.dispatch('geral/setMsgSuccess', res.data.message)
         onSucesso()
      } catch (error) {
         onErro()
         console.log('error: ', error)
         this.dispatch('geral/setObjMsgError', error.response.data)
      }
   },
   setFornecedor({commit}, fornecedor) {
      commit('SET_FORNECEDOR', fornecedor)
   },
}

const mutations = {

   SET_ALL_LIST(state, filtro) {
      state.allList = filtro
   },
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   },
   SET_FORNECEDORES(state, fornecedors) {
      state.all = fornecedors
   },
   SET_FORNECEDOR(state, fornecedor) {
      state.fornecedor = fornecedor
   },

}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
