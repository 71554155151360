import {processaArquivo} from "../../services/GenericAPI";

let url = 'relatorio'
const state = {
}
const getters = {
}
const actions = {
   async printPdf({commit}, {link,filtro,onSucesso, onErro}) {
      try {
         const list = (await processaArquivo(url + '/'+link, filtro))
         onSucesso(list)
      } catch (error) {
         console.log('Erro',error)
         onErro()
      }
   },
}
const mutations = {
}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
