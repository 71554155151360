/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {EventoCaixa} from '../../models/evento_caixa'
import {EventoCaixaSCH, getEventoCaixaSCH} from "../../search/EventoCaixaSCH";
import {ContaSCH} from "../../search/ContaSCH";

const url = '/evento_caixa'

const state = {
  all: [],
  allList: [],
  evento_caixa: new EventoCaixa(),
  filtro: new Filtro()
}

const getters = {
  listaAllEventoCaixas: (state) => {
    return state.allList
  },

  listaEventoCaixas: (state) => {
    return state.all
  },
  pegaEventoCaixa: (state) => {
    return state.evento_caixa
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descricao'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {id, onSucesso,onErro}) {
    try {
      var filtro = getEventoCaixaSCH('evento_caixa_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_EVENTO_CAIXA', list.data[0])
        onSucesso(list.data[0])
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', EventoCaixaSCH(perPage))
  },

  async setAllListEventoCaixas({commit},{onSucesso, onErro}) {
    try {
      var filtro = EventoCaixaSCH('*')
      const list = (await pegaDados(url+'/get', filtro)).data

      commit('SET_ALL_LIST', list.data)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
},
  async setAllEventoCaixas({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_EVENTO_CAIXAS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarEventoCaixa({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.evento_caixa, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setEventoCaixa({commit}, evento_caixa) {
    commit('SET_EVENTO_CAIXA', evento_caixa)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_EVENTO_CAIXAS(state, evento_caixas) {
    state.all = evento_caixas
  },
  SET_EVENTO_CAIXA(state, evento_caixa) {
    state.evento_caixa = evento_caixa
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
